import Vue from 'vue'
import UserSelectVue from './main.vue'

const UserSelectConstructor = Vue.extend(UserSelectVue)

let instance

const initInstance = () => {
  instance = new UserSelectConstructor({
    el: document.createElement('div')
  })
}

const UserSelect = (value, options, callback) => {
  if (!instance) {
    initInstance()
  }

  instance.value = value
  instance.callback = callback
  instance.multiple = !!options.multiple

  if (!instance.visible) {
    document.body.appendChild(instance.$el)
    Vue.nextTick(() => {
      instance.visible = true
    })
  } else {
    instance.visible = true
  }
  instance.initialize()
}

UserSelect.show = (options, callback) => {
  return UserSelect(options.value == null ? '' : options.value, options, callback)
}

UserSelect.show2 = (val, options, callback) => {
  return UserSelect(val, options, callback)
}

UserSelect.hide = () => {
  if (instance) instance.visible = true
}

if (!window.$UserSelect) {
  window.$UserSelect = UserSelect
}

export default UserSelect
