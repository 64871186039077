import Cookies from 'js-cookie'
import session from './session'

const TokenKey = 'auth_token'

export function getToken () {
  let token = session.getItem(TokenKey)
  if (!token) { token = Cookies.get(TokenKey) }
  return token
}

export function setToken (token) {
  const options = { sameSite: 'Lax', expires: 0.5 }
  return Cookies.set(TokenKey, token, options)
}

// 用于临时登录，如通过微信等自动登录等
export function setTmpToken (token) {
  return session.setItem(TokenKey, token)
}

export function removeToken () {
  session.removeItem(TokenKey)
  return Cookies.remove(TokenKey)
}

window.getToken = getToken
