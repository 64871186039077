<template>
  <el-container class="home">
    <el-aside width="auto">
      <LeftMenu />
    </el-aside>
    <el-container>
      <el-header style="padding:0">
        <Header />
      </el-header>
      <el-main id="app-el-main">
        <router-view class="bg-white p-4 rounded-sm" style="min-height: calc(100vh - 100px)" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from '@/components/Header.vue'
import LeftMenu from '@/components/LeftMenu.vue'

export default {
  components: {
    Header,
    LeftMenu
  }

}
</script>
<style scoped>
.home {
  height: 100vh;
}

</style>

