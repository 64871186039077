
import { Loading } from 'element-ui'

const state = {
  loadingCount: 0,
  loadingService: null
}

const mutations = {
  changeLoading: (state, item) => {
    if (item) {
      state.loadingCount++
      if (state.loadingService) return
      state.loadingService = Loading.service({ text: '正在加载中，请稍后', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.08)' })
    } else {
      state.loadingCount--
      if (state.loadingCount < 0) state.loadingCount = 0
      if (state.loadingCount === 0 && state.loadingService) {
        state.loadingService.close()
        state.loadingService = null
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
