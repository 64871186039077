/**
 * Created by teddy on 2020/03/17.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal (path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString (str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function hasText (str) {
  return str && str.trim() !== ''
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray (arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {object} arg
 * @returns {Boolean}
 */
export function isObject (value) {
  return value !== null && Object.prototype.toString.call(value) === '[object Object]'
}

export function isFunction (val) {
  return typeof val === 'function'
}

export function isPromise (val) {
  return isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

export function isEmpty (value) {
  if (value == null) {
    return true
  }

  if (typeof value !== 'object') {
    return true
  }

  return Object.keys(value).length === 0
}
