<template>
  <div class="header">
    <breadcrumb />
    <div class="flex items-center">
      <el-dropdown trigger="click">
        <div class="font-16">{{ user.realName }}</div>
        <el-dropdown-menu slot="dropdown" style="width: 120px;">
          <el-dropdown-item class="flex-center">
            <div @click="passwdDialog=true">修改密码</div>
          </el-dropdown-item>
          <el-dropdown-item class="flex-center">
            <div @click="information()">个人信息</div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-divider direction="vertical" class="divider" />
      <el-image style="width: 25px; height: 30px;" :src="require('@/assets/exit.png')" fit="fit" @click="quit()" />
    </div>
    <el-dialog title="修改密码" :visible.sync="passwdDialog" width="860px">
      <el-form ref="passwd" :model="passwd" :rules="rules" label-width="120px">
        <el-form-item label="原始密码" prop="oldPasswd">
          <el-input v-model="passwd.oldPasswd" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPasswd">
          <el-input v-model="passwd.newPasswd" />
        </el-form-item>
      </el-form>
      <span class="dialog-footer flex-end">
        <el-button type="primary" @click="exitPasswd()">确 定</el-button>
        <el-button @click="passwdDialog = false">取 消</el-button>

      </span>
    </el-dialog>
    <el-dialog title="个人信息" :visible.sync="userDialog" width="860px">
      <el-form ref="user" :model="user" :rules="rulesUser" label-width="120px">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="user.userName" :disabled="true" />
        </el-form-item>
        <el-form-item label="名称" prop="realName">
          <el-input v-model="user.realName" />
        </el-form-item>
        <el-form-item label="电子邮箱" prop="email">
          <el-input v-model="user.email" />
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="user.mobile" />
        </el-form-item>
        <el-form-item label="最后登录时间" prop="lastLoginDate">
          <el-input v-model="user.lastLoginDate" :disabled="true" />
        </el-form-item>
      </el-form>
      <span class="dialog-footer flex-end">
        <el-button type="primary" @click="exitUser()">确 定</el-button>
        <el-button @click="userDialog = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { virtualPath } from '@/config/config.js'
import breadcrumb from './breadcrumb'
export default {
  components: {
    breadcrumb
  },
  data () {
    return {
      passwdDialog: false,
      userDialog: false,
      virtualPath: virtualPath,
      passwd: {
        oldPasswd: '',
        newPasswd: ''
      },
      user: {},
      // 密码规则
      rules: {
        oldPasswd: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPasswd: [{ required: true, message: '请输入新密码', trigger: 'blur' }]
      },
      rulesUser: {
        realName: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.$http({
        url: 'sys/user/info',
        method: 'get'
      }).then(res => {
        this.user = res.data
        // this.realName = res.data.realName
      })
    },
    // 退出登录
    quit () {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({ path: '/login' })
      })
    },
    information () {
      this.userDialog = true
      // this.$router.replace({ path: '/home/personalInformation' })
    },
    exitPasswd () {
      this.$refs.passwd.validate((valid) => {
        if (valid) {
          // 修改密码
          const data = {
            password: this.passwd.oldPasswd,
            newPassword: this.passwd.newPasswd
          }
          this.$http({ url: 'sys/user/update/currentUserPassword', method: 'post', data: data }).then(res => {
            if (res.code === 0) {
              this.$message({ message: '密码修改成功', type: 'success' })
              this.passwd = { oldPasswd: '', newPasswd: '' }
            } else {
              this.$message.error(res.meg)
            }
          })
        } else {
          return false
        }
      })
    },
    exitUser () {
      const data = {
        realName: this.user.realName,
        email: this.user.email,
        mobile: this.user.mobile
      }
      this.$refs.user.validate((valid) => {
        if (valid) {
          this.$http({
            url: 'sys/user/update/currentUserInfo',
            method: 'post',
            data: data
          }).then(res => {
            if (res.code === 0) {
              this.$message({
                message: '修改成功',
                type: 'success'
              })
              this.userDialog = false
              this.init()
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style  scoped>
.header {
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.divider{
  margin: 0px 20px;
}
/* .el-dropdown-menu--small .el-dropdown-menu__item {
  line-height: 35px;
} */
</style>
