<template>
  <div class="p-3">
    <el-alert
      title="菜单配置错误"
      type="warning"
      description="请配置此菜单对应的vue组件地址，例如:/modules/monitor/task-efficiency"
      show-icon
    />
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
