import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import '@/permission' // permission control

import 'element-ui/lib/theme-chalk/index.css'
import '@/config/global.scss'
import '@/styles/tailwindcss.css'
import '@/styles/index.scss'

import '@/components/selector/index.js'

Vue.use(ElementUI, { size: 'small' })
ElementUI.Dialog.props.closeOnClickModal.default = false
Vue.config.productionTip = false

import request from '@/utils/request'
Vue.prototype.$http = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
