import Layout from '@/layout'
import EmptyView from '@/layout/EmptyView'
import $http from '@/utils/request'
import session from '@/utils/session'
import { constantRoutes } from '@/router'
import { getToken } from '@/utils/auth'
import { isExternal } from '@/utils/validate'

const _import = require('@/router/import-' + process.env.NODE_ENV)

function getAsyncRoutes (menuList = []) {
  if (!menuList || menuList.length === 0) return []
  const routes = []
  menuList.forEach(item => {
    const router = mapToRoute(menuList, item, 0)
    if (router) {
      routes.push(router)
    }
  })
  return routes
}

function mapToRoute (menuList, item, level) {
  console.log(menuList)
  console.log(item)
  console.log(level)
  try {
    const router = {}
    router.name = item.id
    if (item.type === 0) {
      router.component = item.component === 'ParentView' ? Layout : (level >= 1 ? Layout : Layout)
      router.path = `/folder/${item.id}`
      router.redirect = getRedirect(item) // 'noRedirect'
      router.children = getAsyncRoutes(item.childMenuList, item.id, ++level)
      router.alwaysShow = true
    } else {
      if (isExternal(item.url)) {
        router.path = getRouterPath(item)
        router.component = Layout
      } else {
        router.path = getRouterPath(item)

        router.component = item.component ? _import(getRouterView(item)) : EmptyView
      }
    }
    router.meta = getMetaData(item)
    return router
  } catch (e) {
    console.error(`路由初始化错误:${item.name}:${item.component}`)
    return null
  }
}

function getMetaData (item) {
  return {
    dynamic: true,
    id: item.id,
    title: item.name,
    parentId: item.parentId,
    icon: item.icon,
    hidden: item.viewType === 99,
    frameUrl: isExternal(item.url) ? getFrameUrl(item.url) : ''
  }
}

function getFrameUrl (url) {
  return url.replace(/\{\{\s*(token)\s*\}\}/, getToken())
}

function getRouterView (item) {
  const view = removePrefix(item.component, '/')
  return view.split('?')[0]
}

function getRouterPath (item) {
  if (isExternal(item.url)) { return `/${item.id}` }

  const path = addPrefix(item.url, '/')
  const paths = path.split('?')
  if (paths.length === 1) return path
  const result = [removeSuffix(paths[0], '/index')]
  var vars = paths[1].split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    result.push(pair[1])
  }
  return result.join('/')
}

function addPrefix (val, prefix) {
  return val.startsWith(prefix) ? val : `${prefix}${val}`
}

function removePrefix (val, prefix) {
  return val.startsWith(prefix) ? val.substr(prefix.length) : val
}

function removeSuffix (val, suffix) {
  return val.endsWith(suffix) ? val.substr(0, val.length - suffix.length) : val
}

function getRedirect (item) {
  if (item.childMenuList && item.childMenuList.length > 0) {
    return item.childMenuList[0].url
  }
  return ''
}

const state = {
  routes: [],
  addRoutes: [],
  topid: 'MAIN_WORKPLACE'
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_TOP_MENU_ID: (state, topid) => {
    state.topid = topid
  }
}

const canUserCacheMenus = function (routers, router) {
  if (!routers) return false
  if (router && router.name != null) return true
  return window.initialized
}

const actions = {
  generateRoutes ({ commit }, router) {
    return new Promise((resolve, reject) => {
      const routers = session.getItem('async-routers')
      if (canUserCacheMenus(routers, router)) {
        const asyncRouters = getAsyncRoutes(routers)
        // console.log(asyncRouters)
        asyncRouters.push({ path: '*', redirect: '/404', hidden: true })
        commit('SET_ROUTES', asyncRouters)
        resolve(asyncRouters)
      } else {
        const random = parseInt(Date.parse(new Date()) / (1000 * 60 * 60))
        $http.get('/sys/menu/menuList', { params: { n: random }}).then(res => {
          const asyncRouters = getAsyncRoutes(res.data)
          // console.log(asyncRouters)
          asyncRouters.push({ path: '*', redirect: '/404', hidden: true })
          session.setItem('async-routers', res.data)
          commit('SET_ROUTES', asyncRouters)
          window.initialized = true
          resolve(asyncRouters)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  resetRoleMenuPerms ({ commit }) {
    session.removeItem('async-routers')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
