<template>
  <div class="leftMenu">
    <div class="title">{{ virtualPath }}</div>
    <el-menu style="width: 98%;" router unique-opened :default-active="$route.path" class="el-menu-vertical-demo" background-color="#304156" text-color="#8a979e">
      <el-menu-item index="/home">
        <i class="el-icon-house" />
        <span slot="title">首页</span>
      </el-menu-item>
      <div v-for="(item,index) in menus" :key="index">
        <template v-if="item.hidden !== true && item.meta?.hidden !== true">
          <el-submenu :index="index + '1'">
            <template slot="title">
              <i style="color: #818693;" :class="item.meta?.icon" />
              <span>{{ item.meta?.title }}</span>
            </template>
            <el-menu-item-group>
              <div v-for="(child, idx) in item.children" :key="idx">
                <el-menu-item class="leftMenu-item" :index="child.path">{{ child.meta?.title }}</el-menu-item>
              </div>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </div>
    </el-menu>
  </div>
</template>
<script>
import { virtualPath } from '@/config/config.js'
export default {
  name: 'LeftMenu',
  data () {
    return {
      virtualPath: virtualPath
    }
  },
  computed: {
    // 菜单列表
    menus () {
      return this.$store.getters.routes
    }
  }
}
</script>
<style scoped>
.title {
  height: 60px;
  width: 200px;
  font-size: 24px;
  font-weight: bold;
  line-height: 60px;
  text-align: center;
  background-color: #409eff;
  color: white;
}
.leftMenu {
display: flex;
  flex-direction: column;
  height: 100%;
  width: 200px;
  text-align: left;
  background-color: #304156;
}
.el-menu-item.is-active {
   background-color: #35465a !important;
   color: #e8eaec;
}
.el-submenu.is-opened {
  background-color: #66b1ff;
}
.el-menu{
  border-right: solid 0px #ffffff;
}
</style>
