import store from '@/store'
import router from '@/router'
import $http from '@/utils/request'
import session from '@/utils/session'
import { resetRouter } from '@/router'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
  token: getToken(),
  account: '',
  info: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    if (token && token !== getToken()) {
      setToken(token)
    }
  },
  SET_USER: (state, user) => {
    if (user) {
      state.info = user
      state.account = user.userName
    } else {
      state.info = {}
      state.account = ''
    }
  },
  SET_ACCOUNT: (state, account) => {
    state.account = account
  }
}

const actions = {
  login ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      $http.post('sys/login/login', userInfo).then(res => {
        setToken(res.token)
        addUserRoutes().then(() => { resolve() }).catch((error) => {
          reject(error)
        })
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo ({ commit, state }) {
    return new Promise((resolve, reject) => {
      const userInfo = session.getItem(state.token)
      if (userInfo) {
        setLoginInfo(commit, userInfo)
        resolve(userInfo)
      } else {
        $http.get('/sys/user/info', { global: false }).then(res => {
          if (!res.data) { reject('验证失败，请重新登录.') }
          res.data.token = getToken()
          setLoginInfo(commit, res.data)
          resolve(res.data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },

  // user logout
  logout ({ commit, state }) {
    return new Promise((resolve, reject) => {
      $http.get('sys/login/logout', {}, {}).then(() => {
        clearLoginInfo(commit)
        resetRouter()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken ({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  setUserInfo ({ commit }, user) {
    setLoginInfo(commit, user)
  }
}

function setLoginInfo (commit, user) {
  const { token } = user
  if (token) { commit('SET_TOKEN', token) }
  session.setItem(getToken(), user)
  commit('SET_USER', user)
}

function clearLoginInfo (commit) {
  removeToken()
  session.clear()
  commit('SET_USER', null)
}

function addUserRoutes () {
  return new Promise((resolve, reject) => {
    store.dispatch('permission/generateRoutes').then((routes) => {
      router.addRoutes(routes)
      resolve(true)
    }).catch(() => {
      reject('生成路由错误')
    })
  })
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

